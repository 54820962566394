export default [
//   {
//     title: 'Home',
//     route: 'home',
//     icon: 'HomeIcon',
//   },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'PieChartIcon',
  },
  {
    title: 'Monitoramento',
    route: 'monitor',
    icon: 'ActivityIcon',
  },
  //   {
  //   title: 'Ativos',
  //   route: 'assets',
  //   icon: 'BoxIcon',
  // },
  {
    title: 'Dispositivos',
    route: 'devices',
    icon: 'CpuIcon',
  },
  //   {
  //   title: 'Geolocalização',
  //   route: 'geolocation',
  //   icon: 'MapPinIcon',
  // },
  {
    title: 'Ajuda',
    route: 'help',
    icon: 'HelpCircleIcon',
  },
]
